import React from 'react';

type Props = { color?: string };

const StarOutlined = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='13'
			height='12'
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 11.91 11.4'
			style={{
				marginLeft: '1px',

				fill: color,
			}}
		>
			<path
				d='M6,1.57l1,2.1a1.77,1.77,0,0,0,1.34,1L10.71,5,9,6.63a1.79,1.79,0,0,0-.52,1.58l.4,2.31L6.83,9.43a1.75,1.75,0,0,0-1.66,0L3.09,10.52l.4-2.31A1.79,1.79,0,0,0,3,6.63L1.29,5l2.33-.34A1.77,1.77,0,0,0,5,3.67l1-2.1M6,.3a.75.75,0,0,0-.7.44L4.06,3.23a.78.78,0,0,1-.59.43l-2.75.4A.79.79,0,0,0,.28,5.4l2,1.94A.78.78,0,0,1,2.5,8L2,10.78a.79.79,0,0,0,.78.92.8.8,0,0,0,.36-.09l2.46-1.29a.73.73,0,0,1,.74,0l2.46,1.29a.8.8,0,0,0,.36.09.79.79,0,0,0,.78-.92L9.5,8a.78.78,0,0,1,.22-.7l2-1.94a.79.79,0,0,0-.44-1.34l-2.75-.4a.78.78,0,0,1-.59-.43L6.7.74A.75.75,0,0,0,6,.3Z'
				transform='translate(-0.04 -0.3)'
			/>
		</svg>
	);
};

export default StarOutlined;
