import React from 'react';

type Props = { color?: string };

const HomeSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='11'
			height='12'
			viewBox='0 0 11 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M6.14979 0.331176C6.03616 0.223786 5.89728 0.138692 5.74247 0.0816181C5.58766 0.0245441 5.42051 -0.00318668 5.25228 0.000290967C5.08405 0.00376862 4.91863 0.0383741 4.76715 0.10178C4.61567 0.165185 4.48164 0.255921 4.37407 0.367883L0 4.91686V10.9804C0 11.2508 0.12417 11.5101 0.345195 11.7014C0.56622 11.8926 0.865995 12 1.17857 12H2.585C2.89758 12 3.19735 11.8926 3.41838 11.7014C3.6394 11.5101 3.76357 11.2508 3.76357 10.9804V8.93359C3.76357 8.66316 3.88774 8.40381 4.10877 8.21259C4.32979 8.02137 4.62957 7.91394 4.94214 7.91394H6.05786C6.37043 7.91394 6.67021 8.02137 6.89123 8.21259C7.11226 8.40381 7.23643 8.66316 7.23643 8.93359V10.9804C7.23643 11.2508 7.3606 11.5101 7.58162 11.7014C7.80265 11.8926 8.10242 12 8.415 12H9.82143C10.134 12 10.4338 11.8926 10.6548 11.7014C10.8758 11.5101 11 11.2508 11 10.9804V4.91686L6.14979 0.331176Z'
				fill={color}
			/>
		</svg>
	);
};

export default HomeSvg;
