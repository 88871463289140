import React from 'react';

type Props = { color?: string };

const Eye = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='21'
			height='13'
			viewBox='0 0 21 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M13.6473 6.49863C13.6473 8.22238 12.2356 9.61975 10.4943 9.61975C8.75298 9.61975 7.34136 8.22238 7.34136 6.49863C7.34136 4.77487 8.75298 3.37752 10.4943 3.37752C12.2356 3.3775 13.6473 4.77489 13.6473 6.49863ZM10.5 0C8.69706 0.00826334 6.82826 0.461771 5.05918 1.33004C3.74567 2.00126 2.46557 2.94828 1.35439 4.11717C0.80864 4.71384 0.112543 5.57782 0 6.49965C0.0133 7.29815 0.842275 8.28375 1.35439 8.88215C2.3964 10.0051 3.64315 10.9257 5.05918 11.67C6.70892 12.4972 8.53496 12.9735 10.5 13C12.3047 12.9916 14.173 12.5329 15.9402 11.67C17.2537 10.9987 18.5344 10.051 19.6456 8.88215C20.1913 8.28547 20.8874 7.42147 21 6.49965C20.9867 5.70114 20.1577 4.71551 19.6456 4.11713C18.6036 2.99415 17.3562 2.07428 15.9402 1.33C14.2913 0.503376 12.4607 0.0303592 10.5 0ZM10.4987 1.61376C13.2308 1.61376 15.4455 3.80153 15.4455 6.50035C15.4455 9.19917 13.2308 11.3869 10.4987 11.3869C7.76659 11.3869 5.55186 9.19914 5.55186 6.50035C5.55186 3.80153 7.76659 1.61376 10.4987 1.61376Z'
				fill={color}
			/>
		</svg>
	);
};

export default Eye;
