import { SvgIcon } from '@mui/material';
import React from 'react';

type Props = { color?: string };

const Sending = ({ color = 'white' }: Props) => {
	return (
		<SvgIcon
			width='51'
			height='22'
			viewBox='0 0 51 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M14.0834 9.41699C14.3936 9.41697 14.697 9.50809 14.9559 9.67903C15.2148 9.84997 15.4177 10.0932 15.5396 10.3785C15.6614 10.6638 15.6967 10.9786 15.6412 11.2838C15.5856 11.5891 15.4417 11.8712 15.2271 12.0953C15.0126 12.3194 14.7369 12.4755 14.4344 12.5443C14.1319 12.613 13.8158 12.5914 13.5255 12.4821C13.2352 12.3728 12.9834 12.1806 12.8013 11.9294C12.6202 11.6794 12.5161 11.382 12.5018 11.0738L12.5001 11.0003L12.5018 10.9269C12.5207 10.521 12.6948 10.1378 12.9884 9.85673C13.2831 9.57455 13.6753 9.41702 14.0834 9.41699Z'
				fill={color}
				stroke={color}
			/>
			<path
				d='M8.08336 9.41699C8.39359 9.41697 8.69699 9.50809 8.95588 9.67903C9.21477 9.84997 9.41774 10.0932 9.53957 10.3785C9.66141 10.6638 9.69675 10.9786 9.6412 11.2838C9.58565 11.5891 9.44165 11.8712 9.22711 12.0953C9.01256 12.3194 8.73691 12.4755 8.4344 12.5443C8.13188 12.613 7.81583 12.5914 7.5255 12.4821C7.23517 12.3728 6.98335 12.1806 6.80132 11.9294C6.62022 11.6794 6.51614 11.382 6.50183 11.0738L6.50013 11.0003L6.50183 10.9269C6.52067 10.521 6.69485 10.1378 6.9884 9.85673C7.28309 9.57455 7.67535 9.41702 8.08336 9.41699Z'
				fill={color}
				stroke={color}
			/>
			<path
				d='M2.08336 9.41699C2.39359 9.41697 2.69699 9.50809 2.95588 9.67903C3.21477 9.84997 3.41774 10.0932 3.53957 10.3785C3.66141 10.6638 3.69675 10.9786 3.6412 11.2838C3.58565 11.5891 3.44165 11.8712 3.22711 12.0953C3.01256 12.3194 2.73691 12.4755 2.4344 12.5443C2.13188 12.613 1.81583 12.5914 1.5255 12.4821C1.23517 12.3728 0.983353 12.1806 0.801324 11.9294C0.620223 11.6794 0.516141 11.382 0.501825 11.0738L0.500133 11.0003L0.501825 10.9269C0.520674 10.521 0.694847 10.1378 0.9884 9.85673C1.28309 9.57455 1.67535 9.41702 2.08336 9.41699Z'
				fill={color}
				stroke={color}
			/>
			<path
				d='M47.5488 10.5417C47.5488 10.9174 47.3996 11.2777 47.1339 11.5434C46.8682 11.8091 46.5079 11.9583 46.1322 11.9583L22.1738 11.9583C21.7981 11.9583 21.4378 11.8091 21.1721 11.5434C20.9064 11.2777 20.7572 10.9174 20.7572 10.5417C20.7572 10.1659 20.9064 9.80561 21.1721 9.53993C21.4378 9.27425 21.7981 9.125 22.1738 9.125L46.1322 9.125C46.5079 9.125 46.8682 9.27426 47.1339 9.53993C47.3996 9.80561 47.5488 10.1659 47.5488 10.5417Z'
				fill={color}
				stroke={color}
			/>
			<path
				d='M50.0078 10.5417L50.0078 10.5419C50.0077 10.9176 49.8584 11.2779 49.5927 11.5435L49.5927 11.5436L40.9677 20.1686L40.9676 20.1687C40.7019 20.4346 40.3415 20.584 39.9657 20.5841C39.5899 20.5842 39.2294 20.4351 38.9635 20.1694C38.6977 19.9038 38.5483 19.5434 38.5481 19.1676C38.548 18.7918 38.6971 18.4314 38.9627 18.1656C38.9627 18.1655 38.9627 18.1655 38.9628 18.1654C38.9628 18.1654 38.9628 18.1654 38.9628 18.1654L46.2345 10.8957L46.5881 10.5421L46.2345 10.1885L38.9647 2.91871C38.9647 2.91867 38.9647 2.91863 38.9646 2.91858C38.699 2.65275 38.5499 2.29232 38.5501 1.91655C38.5502 1.54073 38.6996 1.18035 38.9655 0.914691C39.2313 0.649036 39.5918 0.499867 39.9676 0.5C40.3434 0.500132 40.7038 0.649557 40.9695 0.9154L40.9696 0.915525L49.5941 9.54008C49.5942 9.54018 49.5943 9.54028 49.5944 9.54037C49.8594 9.80619 50.0081 10.1663 50.0078 10.5417Z'
				fill={color}
				stroke={color}
			/>
		</SvgIcon>
	);
};

export default Sending;
