import React from 'react';

type Props = { color?: string };

const Delivered = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='28'
			height='23'
			viewBox='0 0 39 35'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M16 23.875L21.25 25.625C21.25 25.625 34.375 23 36.125 23C37.875 23 37.875 24.75 36.125 26.5C34.375 28.25 28.25 33.5 23 33.5C17.75 33.5 14.25 30.875 10.75 30.875H2'
				stroke={color}
				stroke-width='3'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M2 20.375C3.75 18.625 7.25 16 10.75 16C14.25 16 22.5625 19.5 23.875 21.25C25.1875 23 21.25 25.625 21.25 25.625M12.5 10.75V3.75C12.5 3.28587 12.6844 2.84075 13.0126 2.51256C13.3408 2.18437 13.7859 2 14.25 2H35.25C35.7141 2 36.1592 2.18437 36.4874 2.51256C36.8156 2.84075 37 3.28587 37 3.75V17.75'
				stroke={color}
				stroke-width='3'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M20.375 2H29.125V9.875H20.375V2Z'
				fill={color}
				stroke={color}
				stroke-width='3'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	);
};

export default Delivered;
