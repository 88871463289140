import React from 'react';

type Props = { color?: string };

const Password = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='26'
			height='12'
			viewBox='0 0 44 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.68064e-08 12.0009C-0.00020677 9.33349 0.871094 6.74208 2.47626 4.636C4.08142 2.52993 6.32894 1.02926 8.86376 0.371072C11.3986 -0.287119 14.0762 -0.0653116 16.4736 1.00145C18.871 2.06822 20.8515 3.91913 22.1023 6.26178H40.4186C42.3955 6.26178 44 7.89794 44 9.91393V15.1313C44 15.5464 43.8383 15.9445 43.5504 16.2381C43.2626 16.5316 42.8722 16.6965 42.4651 16.6965H37.8605V20.3486C37.8605 20.7638 37.6988 21.1619 37.4109 21.4554C37.1231 21.7489 36.7327 21.9138 36.3256 21.9138H31.2093C30.8022 21.9138 30.4118 21.7489 30.124 21.4554C29.8361 21.1619 29.6744 20.7638 29.6744 20.3486V16.6965H22.5996C21.5375 19.2423 19.6455 21.3358 17.2438 22.6227C14.8421 23.9095 12.0782 24.3107 9.41974 23.7584C6.76131 23.206 4.37164 21.734 2.65512 19.5915C0.938601 17.449 0.000653501 14.7675 3.68064e-08 12.0009ZM11.7674 7.82699C10.6819 7.82699 9.64083 8.26674 8.87324 9.04949C8.10565 9.83224 7.67442 10.8939 7.67442 12.0009C7.67442 13.1079 8.10565 14.1695 8.87324 14.9523C9.64083 15.735 10.6819 16.1748 11.7674 16.1748C12.853 16.1748 13.8941 15.735 14.6616 14.9523C15.4292 14.1695 15.8605 13.1079 15.8605 12.0009C15.8605 10.8939 15.4292 9.83224 14.6616 9.04949C13.8941 8.26674 12.853 7.82699 11.7674 7.82699Z'
				fill={color}
			/>
		</svg>
	);
};

export default Password;
