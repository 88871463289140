import { SvgIcon } from '@mui/material';
import React from 'react';

type Props = { color?: string };

const Pending = ({ color = 'white' }: Props) => {
	return (
		<SvgIcon
			width='41'
			height='41'
			viewBox='0 0 41 41'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M2.125 20.5C2.125 22.913 2.60028 25.3025 3.52371 27.5318C4.44714 29.7612 5.80064 31.7868 7.50691 33.4931C9.21319 35.1994 11.2388 36.5529 13.4682 37.4763C15.6976 38.3997 18.087 38.875 20.5 38.875C22.913 38.875 25.3025 38.3997 27.5318 37.4763C29.7612 36.5529 31.7868 35.1994 33.4931 33.4931C35.1994 31.7868 36.5529 29.7612 37.4763 27.5318C38.3997 25.3025 38.875 22.913 38.875 20.5C38.875 15.6266 36.9391 10.9529 33.4931 7.50691C30.0471 4.06093 25.3734 2.125 20.5 2.125C15.6266 2.125 10.9529 4.06093 7.50691 7.50691C4.06093 10.9529 2.125 15.6266 2.125 20.5Z'
				stroke={color}
				stroke-width='4'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M20.5 10.292V20.5003L26.625 26.6253'
				stroke={color}
				stroke-width='4'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</SvgIcon>
	);
};

export default Pending;
