import React from 'react';

const CategoriesSvg = () => {
	return (
		<svg
			width='20'
			height='15'
			viewBox='0 0 20 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect width='6' height='6' rx='1' fill='white' />
			<rect y='7' width='6' height='6' rx='1' fill='white' />
			<rect x='7' y='7' width='6' height='6' rx='1' fill='white' />
			<circle cx='10' cy='3' r='3' fill='white' />
		</svg>
	);
};
export default CategoriesSvg;
