import React from 'react';

type Props = { color?: string };

const CatalogSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='13'
			height='13'
			viewBox='0 0 13 13'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M1 1.97059C1 1.43353 1.43353 1 1.97059 1C2.50765 1 2.94118 1.43353 2.94118 1.97059C2.94118 2.50765 2.50765 2.94118 1.97059 2.94118C1.43353 2.94118 1 2.50765 1 1.97059Z'
				fill={color}
				stroke={color}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M1 6.49989C1 5.96283 1.43353 5.5293 1.97059 5.5293C2.50765 5.5293 2.94118 5.96283 2.94118 6.49989C2.94118 7.03694 2.50765 7.47047 1.97059 7.47047C1.43353 7.47047 1 7.03694 1 6.49989Z'
				fill={color}
				stroke={color}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M1 11.0292C1 10.4921 1.43353 10.0586 1.97059 10.0586C2.50765 10.0586 2.94118 10.4921 2.94118 11.0292C2.94118 11.5662 2.50765 11.9998 1.97059 11.9998C1.43353 11.9998 1 11.5662 1 11.0292Z'
				fill={color}
				stroke={color}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M4.88232 1.97059C4.88232 1.43353 5.31585 1 5.85291 1H11.0294C11.5664 1 12 1.43353 12 1.97059C12 2.50765 11.5664 2.94118 11.0294 2.94118H5.85291C5.31585 2.94118 4.88232 2.50765 4.88232 1.97059Z'
				fill={color}
				stroke={color}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M4.88232 6.49989C4.88232 5.96283 5.31585 5.5293 5.85291 5.5293H11.0294C11.5664 5.5293 12 5.96283 12 6.49989C12 7.03694 11.5664 7.47047 11.0294 7.47047H5.85291C5.31585 7.47047 4.88232 7.03694 4.88232 6.49989Z'
				fill={color}
				stroke={color}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M4.88232 11.0292C4.88232 10.4921 5.31585 10.0586 5.85291 10.0586H11.0294C11.5664 10.0586 12 10.4921 12 11.0292C12 11.5662 11.5664 11.9998 11.0294 11.9998H5.85291C5.31585 11.9998 4.88232 11.5662 4.88232 11.0292Z'
				fill={color}
				stroke={color}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default CatalogSvg;
