import React from 'react';

type Props = { color?: string };

const FilterSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='30'
			height='18'
			viewBox='0 0 30 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0.75 2.08333C0.75 1.66341 0.916815 1.26068 1.21375 0.963748C1.51068 0.666815 1.91341 0.5 2.33333 0.5H27.6667C28.0866 0.5 28.4893 0.666815 28.7863 0.963748C29.0832 1.26068 29.25 1.66341 29.25 2.08333C29.25 2.50326 29.0832 2.90599 28.7863 3.20292C28.4893 3.49985 28.0866 3.66667 27.6667 3.66667H2.33333C1.91341 3.66667 1.51068 3.49985 1.21375 3.20292C0.916815 2.90599 0.75 2.50326 0.75 2.08333ZM3.91667 9.20833C3.91667 8.78841 4.08348 8.38568 4.38041 8.08875C4.67735 7.79181 5.08007 7.625 5.5 7.625H24.5C24.9199 7.625 25.3227 7.79181 25.6196 8.08875C25.9165 8.38568 26.0833 8.78841 26.0833 9.20833C26.0833 9.62826 25.9165 10.031 25.6196 10.3279C25.3227 10.6249 24.9199 10.7917 24.5 10.7917H5.5C5.08007 10.7917 4.67735 10.6249 4.38041 10.3279C4.08348 10.031 3.91667 9.62826 3.91667 9.20833ZM8.66667 16.3333C8.66667 15.9134 8.83348 15.5107 9.13041 15.2137C9.42735 14.9168 9.83007 14.75 10.25 14.75H19.75C20.1699 14.75 20.5727 14.9168 20.8696 15.2137C21.1665 15.5107 21.3333 15.9134 21.3333 16.3333C21.3333 16.7533 21.1665 17.156 20.8696 17.4529C20.5727 17.7499 20.1699 17.9167 19.75 17.9167H10.25C9.83007 17.9167 9.42735 17.7499 9.13041 17.4529C8.83348 17.156 8.66667 16.7533 8.66667 16.3333Z'
				fill={color}
			/>
		</svg>
	);
};

export default FilterSvg;
