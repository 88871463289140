import React from 'react';

type Props = { color?: string };

const EditProfile = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='17'
			height='18'
			viewBox='0 0 17 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.32 3.17505H2C0.895 3.17505 0 4.12405 0 5.29305V15.881C0 17.051 0.895 17.999 2 17.999H13C14.105 17.999 15 17.051 15 15.881V8.13105L11.086 12.275C10.7442 12.6405 10.2991 12.8931 9.81 12.999L7.129 13.567C5.379 13.937 3.837 12.304 4.187 10.452L4.723 7.61305C4.82 7.10105 5.058 6.63005 5.407 6.26105L8.32 3.17505Z'
				fill={color}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.8457 1.31704C16.7446 1.06107 16.5964 0.826345 16.4087 0.625042C16.2242 0.428171 16.0017 0.270677 15.7547 0.162042C15.5114 0.0551784 15.2485 0 14.9827 0C14.7169 0 14.454 0.0551784 14.2107 0.162042C13.9637 0.270677 13.7412 0.428171 13.5567 0.625042L13.0107 1.20304L15.8627 4.22304L16.4087 3.64404C16.5983 3.44427 16.7468 3.20914 16.8457 2.95204C17.0517 2.42651 17.0517 1.84257 16.8457 1.31704ZM14.4497 5.72004L11.5967 2.69904L6.8197 7.75904C6.74922 7.83414 6.70169 7.92782 6.6827 8.02904L6.1467 10.869C6.0767 11.239 6.3857 11.565 6.7347 11.491L9.4167 10.924C9.51429 10.9023 9.60311 10.8518 9.6717 10.779L14.4497 5.72004Z'
				fill={color}
			/>
		</svg>
	);
};

export default EditProfile;
