import React from 'react';

type Props = { color?: string };

const CloseSession = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='15'
			height='17'
			viewBox='0 0 32 35'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M22.4346 11.1982L29.3688 18.1325'
				stroke={color}
				stroke-width='4'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M22.4346 26.4539L29.3688 18.1328'
				stroke={color}
				stroke-width='4'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M29.3688 18.1328L11.3398 18.1328'
				stroke={color}
				stroke-width='4'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M11 2H3C2.44772 2 2 2.44772 2 3V32C2 32.5523 2.44772 33 3 33H11'
				stroke={color}
				stroke-width='4'
				stroke-linecap='round'
			/>
		</svg>
	);
};

export default CloseSession;
