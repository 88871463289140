import React from 'react';

type Props = { color?: string };

const AdminSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='11'
			height='14'
			viewBox='0 0 11 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M5.5 12.5823C6.61146 12.2278 7.53958 11.528 8.28437 10.4826C9.02917 9.4373 9.46458 8.27051 9.59062 6.98228H5.5V1.4L1.375 2.99494V6.66329C1.375 6.74599 1.38646 6.85232 1.40938 6.98228H5.5V12.5823ZM5.5 14C5.41979 14 5.34531 13.9941 5.27656 13.9823C5.20781 13.9705 5.13906 13.9527 5.07031 13.9291C3.52344 13.3975 2.29167 12.414 1.375 10.9788C0.458333 9.54363 0 7.99879 0 6.3443V2.99494C0 2.69958 0.0831874 2.43376 0.249562 2.19747C0.415937 1.96118 0.630667 1.78987 0.89375 1.68354L5.01875 0.0886076C5.17917 0.0295359 5.33958 0 5.5 0C5.66042 0 5.82083 0.0295359 5.98125 0.0886076L10.1062 1.68354C10.3698 1.78987 10.5847 1.96118 10.7511 2.19747C10.9175 2.43376 11.0005 2.69958 11 2.99494V6.3443C11 7.99831 10.5417 9.54316 9.625 10.9788C8.70833 12.4145 7.47656 13.3979 5.92969 13.9291C5.86094 13.9527 5.79219 13.9705 5.72344 13.9823C5.65469 13.9941 5.58021 14 5.5 14Z'
				fill={color}
			/>
		</svg>
	);
};

export default AdminSvg;
