import React from 'react';

type CompletedProps = { color?: string };

export const Completed = ({ color = 'white' }: CompletedProps) => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 43 41'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M2 20.375C2 22.788 2.47528 25.1775 3.39871 27.4068C4.32214 29.6362 5.67564 31.6618 7.38191 33.3681C9.08819 35.0744 11.1138 36.4279 13.3432 37.3513C15.5726 38.2747 17.962 38.75 20.375 38.75C22.788 38.75 25.1775 38.2747 27.4068 37.3513C29.6362 36.4279 31.6618 35.0744 33.3681 33.3681C35.0744 31.6618 36.4279 29.6362 37.3513 27.4068C38.2747 25.1775 38.75 22.788 38.75 20.375C38.75 15.5016 36.8141 10.8279 33.3681 7.38191C29.9221 3.93593 25.2484 2 20.375 2C15.5016 2 10.8279 3.93593 7.38191 7.38191C3.93593 10.8279 2 15.5016 2 20.375Z'
				stroke={color}
				stroke-width='4'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M41.0441 5.10456L41.0442 5.10462C41.4309 5.4914 41.6481 6.01591 41.6481 6.56281C41.6481 7.10968 41.4309 7.63417 41.0443 8.02094C41.0442 8.02096 41.0442 8.02098 41.0442 8.02099L21.8375 27.2277L21.8374 27.2278C21.63 27.4352 21.3838 27.5998 21.1128 27.712C20.8418 27.8243 20.5514 27.8821 20.258 27.8821C19.9647 27.8821 19.6742 27.8243 19.4033 27.712C19.1323 27.5998 18.886 27.4352 18.6786 27.2278L18.6786 27.2277L9.13582 17.6867L9.13588 17.6866L9.12966 17.6806C8.93267 17.4903 8.77554 17.2628 8.66745 17.0111C8.55935 16.7595 8.50246 16.4888 8.50008 16.215C8.4977 15.9411 8.54988 15.6695 8.65359 15.4161C8.75729 15.1626 8.91044 14.9323 9.10409 14.7387C9.29775 14.545 9.52803 14.3919 9.7815 14.2882C10.035 14.1845 10.3066 14.1323 10.5804 14.1346C10.8543 14.137 11.1249 14.1939 11.3766 14.302C11.6282 14.4101 11.8558 14.5672 12.046 14.7642L12.046 14.7643L12.0521 14.7704L19.9036 22.6219L20.2572 22.9755L20.6107 22.6219L38.1263 5.10466L38.1264 5.10456C38.3179 4.9129 38.5454 4.76086 38.7957 4.65712C39.046 4.55339 39.3143 4.5 39.5853 4.5C39.8562 4.5 40.1245 4.55339 40.3749 4.65712C40.6252 4.76086 40.8526 4.9129 41.0441 5.10456Z'
				fill={color}
				stroke={color}
			/>
		</svg>
	);
};
