import { SvgIcon } from '@mui/material';
import React from 'react';

export const PaymentsSvg = () => {
	return (
		<SvgIcon
			xmlns='http://www.w3.org/2000/svg'
			xmlnsXlink='http://www.w3.org/1999/xlink'
			viewBox='0 0 96 96'
			width='96'
			height='96'
			preserveAspectRatio='xMidYMid meet'
			style={{
				width: '100%',
				height: '100%',
				transform: 'translate3d(0px, 0px, 0px)',
				contentVisibility: 'visible',
				animation: 'spin 2s linear infinite',
			}}
		>
			<defs>
				<clipPath id='__lottie_element_1129'>
					<rect width='96' height='96' x='0' y='0'></rect>
				</clipPath>
				<g id='__lottie_element_1133'>
					<g
						transform='matrix(0.9999997615814209,0.0006577151943929493,-0.0006577151943929493,0.9999997615814209,27.055370330810547,27.967121124267578)'
						opacity='1'
						style={{ display: 'block;' }}
					>
						<path
							fill='rgb(255,0,0)'
							fill-opacity='1'
							d=' M10.01,5.01 C10.01,5.01 45,5.01 45,5.01 C47.76,5.01 50,7.25 50,10.01 C50,10.01 50,29 50,29 C50,31.76 47.76,34 45,34 C45,34 10.01,34 10.01,34 C7.25,34 5.01,31.76 5.01,29 C5.01,29 5.01,10.01 5.01,10.01 C5.01,7.25 7.25,5.01 10.01,5.01z M13.00832462310791,25.002023696899414 C13.00832462310791,25.002023696899414 29.66057586669922,25.002023696899414 29.66057586669922,25.002023696899414 M5.59,11.49 C5.59,11.49 49.42,11.49 49.42,11.49 C49.42,11.49 49.42,18.5 49.42,18.5 C49.42,18.5 5.59,18.5 5.59,18.5 C5.59,18.5 5.59,11.49 5.59,11.49z'
						></path>
						<g opacity='1' transform='matrix(1,0,0,1,27.5,19.5)'></g>
						<g opacity='1' transform='matrix(1,0,0,1,0,0)'></g>
						<g opacity='1' transform='matrix(1,0,0,1,27.5,14.98900032043457)'>
							<path
								fill='rgb(0,0,0)'
								fill-opacity='1'
								d=' M-21.90500831604004,-3.5019333362579346 C-21.90500831604004,-3.5019333362579346 21.915132522583008,-3.5019333362579346 21.915132522583008,-3.5019333362579346 C21.915132522583008,-3.5019333362579346 21.915132522583008,3.5104875564575195 21.915132522583008,3.5104875564575195 C21.915132522583008,3.5104875564575195 -21.90500831604004,3.5104875564575195 -21.90500831604004,3.5104875564575195 C-21.90500831604004,3.5104875564575195 -21.90500831604004,-3.5019333362579346 -21.90500831604004,-3.5019333362579346z'
							></path>
						</g>
					</g>
				</g>
				<g id='__lottie_element_1139'>
					<g
						transform='matrix(0.9999997615814209,0.0006577151943929493,-0.0006577151943929493,0.9999997615814209,27.055370330810547,27.967121124267578)'
						opacity='1'
						style={{ display: 'block' }}
					>
						<path
							fill='rgb(255,0,0)'
							fill-opacity='1'
							d=' M10.01,5.01 C10.01,5.01 45,5.01 45,5.01 C47.76,5.01 50,7.25 50,10.01 C50,10.01 50,29 50,29 C50,31.76 47.76,34 45,34 C45,34 10.01,34 10.01,34 C7.25,34 5.01,31.76 5.01,29 C5.01,29 5.01,10.01 5.01,10.01 C5.01,7.25 7.25,5.01 10.01,5.01z M13.00832462310791,25.002023696899414 C13.00832462310791,25.002023696899414 29.66057586669922,25.002023696899414 29.66057586669922,25.002023696899414 M5.59,11.49 C5.59,11.49 49.42,11.49 49.42,11.49 C49.42,11.49 49.42,18.5 49.42,18.5 C49.42,18.5 5.59,18.5 5.59,18.5 C5.59,18.5 5.59,11.49 5.59,11.49z'
						></path>
						<g opacity='1' transform='matrix(1,0,0,1,27.5,19.5)'></g>
						<g opacity='1' transform='matrix(1,0,0,1,0,0)'></g>
						<g opacity='1' transform='matrix(1,0,0,1,27.5,14.98900032043457)'>
							<path
								fill='rgb(0,0,0)'
								fill-opacity='1'
								d=' M-21.90500831604004,-3.5019333362579346 C-21.90500831604004,-3.5019333362579346 21.915132522583008,-3.5019333362579346 21.915132522583008,-3.5019333362579346 C21.915132522583008,-3.5019333362579346 21.915132522583008,3.5104875564575195 21.915132522583008,3.5104875564575195 C21.915132522583008,3.5104875564575195 -21.90500831604004,3.5104875564575195 -21.90500831604004,3.5104875564575195 C-21.90500831604004,3.5104875564575195 -21.90500831604004,-3.5019333362579346 -21.90500831604004,-3.5019333362579346z'
							></path>
						</g>
					</g>
				</g>
				<filter
					id='__lottie_element_1145'
					filterUnits='objectBoundingBox'
					x='0%'
					y='0%'
					width='100%'
					height='100%'
				>
					<feComponentTransfer in='SourceGraphic'>
						<feFuncA type='table' tableValues='1.0 0.0'></feFuncA>
					</feComponentTransfer>
				</filter>
				<mask id='__lottie_element_1139_2' mask-type='alpha'>
					<g filter='url(#__lottie_element_1145)'>
						<rect
							width='96'
							height='96'
							x='0'
							y='0'
							fill='#ffffff'
							opacity='0'
						></rect>
						<use xlinkHref='#__lottie_element_1139'></use>
					</g>
				</mask>
				<filter
					id='__lottie_element_1146'
					filterUnits='objectBoundingBox'
					x='0%'
					y='0%'
					width='100%'
					height='100%'
				>
					<feComponentTransfer in='SourceGraphic'>
						<feFuncA type='table' tableValues='1.0 0.0'></feFuncA>
					</feComponentTransfer>
				</filter>
				<mask id='__lottie_element_1133_2' mask-type='alpha'>
					<g filter='url(#__lottie_element_1146)'>
						<rect
							width='96'
							height='96'
							x='0'
							y='0'
							fill='#ffffff'
							opacity='0'
						></rect>
						<use xlinkHref='#__lottie_element_1133'></use>
					</g>
				</mask>
			</defs>
			<g clip-path='url(#__lottie_element_1129)'>
				<g
					mask='url(#__lottie_element_1133_2)'
					style={{ display: 'block' }}
					className='lottie_element_1133_2'
				>
					<g
						transform='matrix(1,-0.00011606739280978218,0.00011606739280978218,1,16.99489402770996,4.0040740966796875)'
						opacity='1'
					>
						<path
							fill='rgb(0,255,144)'
							fill-opacity='1'
							d=' M17,16 C17,16 37,16 37,16 M48,60 C48,60 48,13 48,13 C48,8.58 44.42,5 40,5 C40,5 13,5 13,5 C8.58,5 5,8.58 5,13 C5,13 5,75 5,75 C5,79.42 8.58,83 13,83 C13,83 40,83 40,83 C44.42,83 48,79.42 48,75 C48,75 48,60 48,60z M29.5,69.5 C30.88,70.88 30.88,73.12 29.5,74.5 C28.12,75.88 25.88,75.88 24.5,74.5 C23.12,73.12 23.12,70.88 24.5,69.5 C25.88,68.12 28.12,68.12 29.5,69.5z'
						></path>
						<g opacity='1' transform='matrix(1,0,0,1,0,0)'>
							<path
								stroke-linecap='round'
								stroke-linejoin='miter'
								fill-opacity='0'
								stroke-miterlimit='4'
								stroke='rgb(0,0,0)'
								stroke-opacity='1'
								stroke-width='5'
								d=' M17,16 C17,16 37,16 37,16'
							></path>
						</g>
						<g opacity='1' transform='matrix(1,0,0,1,26.5,44)'>
							<path
								stroke-linecap='butt'
								stroke-linejoin='miter'
								fill-opacity='0'
								stroke-miterlimit='4'
								stroke='rgb(0,0,0)'
								stroke-opacity='1'
								stroke-width='5'
								d=' M21.5,16 C21.5,16 21.5,-31 21.5,-31 C21.5,-35.417999267578125 17.917999267578125,-39 13.5,-39 C13.5,-39 -13.5,-39 -13.5,-39 C-17.917999267578125,-39 -21.5,-35.417999267578125 -21.5,-31 C-21.5,-31 -21.5,31 -21.5,31 C-21.5,35.417999267578125 -17.917999267578125,39 -13.5,39 C-13.5,39 13.5,39 13.5,39 C17.917999267578125,39 21.5,35.417999267578125 21.5,31 C21.5,31 21.5,16 21.5,16z'
							></path>
						</g>
						<g
							opacity='1'
							transform='matrix(1,0,0,1,26.99799919128418,71.99800109863281)'
						>
							<path
								fill='rgb(0,0,0)'
								fill-opacity='1'
								d=' M2.499000072479248,-2.5 C3.878999948501587,-1.1200000047683716 3.878999948501587,1.1180000305175781 2.499000072479248,2.499000072479248 C1.11899995803833,3.878999948501587 -1.11899995803833,3.878999948501587 -2.499000072479248,2.499000072479248 C-3.880000114440918,1.1180000305175781 -3.880000114440918,-1.1200000047683716 -2.499000072479248,-2.5 C-1.11899995803833,-3.880000114440918 1.11899995803833,-3.880000114440918 2.499000072479248,-2.5z'
							></path>
						</g>
					</g>
				</g>
				<g mask='url(#__lottie_element_1139_2)' style={{ display: 'block' }}>
					<g
						transform='matrix(0.9999999403953552,-0.0004126840503886342,0.0004126840503886342,0.9999999403953552,34.491539001464844,36.50433349609375)'
						opacity='1'
					>
						<g opacity='1' transform='matrix(1,0,0,1,0,0)'>
							<path
								stroke-linecap='round'
								stroke-linejoin='miter'
								fill-opacity='0'
								stroke-miterlimit='4'
								stroke='rgb(0,0,0)'
								stroke-opacity='1'
								stroke-width='5'
								d=' M10.00100040435791,2.5 C10.00100040435791,2.5 10.00100040435791,4.5 10.00100040435791,4.5'
							></path>
						</g>
						<g opacity='1' transform='matrix(1,0,0,1,0,0)'>
							<path
								stroke-linecap='round'
								stroke-linejoin='miter'
								fill-opacity='0'
								stroke-miterlimit='4'
								stroke='rgb(0,0,0)'
								stroke-opacity='1'
								stroke-width='5'
								d=' M10.00100040435791,19.5 C10.00100040435791,19.5 10.00100040435791,20.5 10.00100040435791,20.5'
							></path>
						</g>
						<g opacity='1' transform='matrix(1,0,0,1,10.00100040435791,8.5)'>
							<path
								stroke-linecap='round'
								stroke-linejoin='miter'
								fill-opacity='0'
								stroke-miterlimit='4'
								stroke='rgb(0,0,0)'
								stroke-opacity='1'
								stroke-width='5'
								d=' M5,-3 C5,-3 -2,-3 -2,-3 C-3.6570000648498535,-3 -5,-1.656999945640564 -5,0 C-5,1.656999945640564 -3.6570000648498535,3 -2,3 C-2,3 1.315999984741211,3 1.315999984741211,3'
							></path>
						</g>
						<g opacity='1' transform='matrix(1,0,0,1,10.5,14.5)'>
							<path
								stroke-linecap='round'
								stroke-linejoin='miter'
								fill-opacity='0'
								stroke-miterlimit='4'
								stroke='rgb(0,0,0)'
								stroke-opacity='1'
								stroke-width='5'
								d=' M-2.2019999027252197,-3 C-2.2019999027252197,-3 2.5,-3 2.5,-3 C4.1570000648498535,-3 5.5,-1.656999945640564 5.5,0 C5.5,1.656999945640564 4.1570000648498535,3 2.5,3 C2.5,3 -5.5,3 -5.5,3'
							></path>
						</g>
					</g>
				</g>
				<g
					transform='matrix(0.9999997615814209,0.0006577151943929493,-0.0006577151943929493,0.9999997615814209,27.055370330810547,27.967121124267578)'
					opacity='1'
					style={{ display: 'block' }}
				>
					<g opacity='1' transform='matrix(1,0,0,1,27.5,19.5)'>
						<path
							stroke-linecap='butt'
							stroke-linejoin='miter'
							fill-opacity='0'
							stroke-miterlimit='4'
							stroke='rgb(0,0,0)'
							stroke-opacity='1'
							stroke-width='5'
							d=' M-17.49100112915039,-14.493475914001465 C-17.49100112915039,-14.493475914001465 17.50112533569336,-14.493475914001465 17.50112533569336,-14.493475914001465 C20.261503219604492,-14.493475914001465 22.5,-12.254979133605957 22.5,-9.494601249694824 C22.5,-9.494601249694824 22.5,9.501124382019043 22.5,9.501124382019043 C22.5,12.261503219604492 20.261503219604492,14.5 17.50112533569336,14.5 C17.50112533569336,14.5 -17.49100112915039,14.5 -17.49100112915039,14.5 C-20.251379013061523,14.5 -22.48987579345703,12.261503219604492 -22.48987579345703,9.501124382019043 C-22.48987579345703,9.501124382019043 -22.48987579345703,-9.494601249694824 -22.48987579345703,-9.494601249694824 C-22.48987579345703,-12.254979133605957 -20.251379013061523,-14.493475914001465 -17.49100112915039,-14.493475914001465z'
						></path>
					</g>
					<g opacity='1' transform='matrix(1,0,0,1,0,0)'>
						<path
							stroke-linecap='round'
							stroke-linejoin='miter'
							fill-opacity='0'
							stroke-miterlimit='4'
							stroke='rgb(0,0,0)'
							stroke-opacity='1'
							stroke-width='5'
							d=' M13.00832462310791,25.002023696899414 C13.00832462310791,25.002023696899414 29.66057586669922,25.002023696899414 29.66057586669922,25.002023696899414'
						></path>
					</g>
					<g opacity='1' transform='matrix(1,0,0,1,27.5,14.98900032043457)'>
						<path
							fill='rgb(0,0,0)'
							fill-opacity='1'
							d=' M-21.90500831604004,-3.5019333362579346 C-21.90500831604004,-3.5019333362579346 21.915132522583008,-3.5019333362579346 21.915132522583008,-3.5019333362579346 C21.915132522583008,-3.5019333362579346 21.915132522583008,3.5104875564575195 21.915132522583008,3.5104875564575195 C21.915132522583008,3.5104875564575195 -21.90500831604004,3.5104875564575195 -21.90500831604004,3.5104875564575195 C-21.90500831604004,3.5104875564575195 -21.90500831604004,-3.5019333362579346 -21.90500831604004,-3.5019333362579346z'
						></path>
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
