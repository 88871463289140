import { SvgIcon } from '@mui/material';
import React from 'react';

type Props = { color?: string };

const Paid = ({ color = 'white' }: Props) => {
	return (
		<SvgIcon
			width='22'
			height='38'
			viewBox='0 0 22 38'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M12.3333 34.067V37.5H9.66667V34.0784V33.6381L9.22995 33.5824C4.7832 33.0151 0.806759 30.3982 0.516902 26.15H3.19779C3.35865 27.4225 4.12704 28.4135 5.12837 29.1369C6.25034 29.9474 7.70273 30.455 9.07826 30.7021L9.66667 30.8078V30.21V20.767V20.3325L9.23636 20.2719C7.16272 19.98 4.96024 19.1874 3.28844 17.8682C1.62808 16.558 0.5 14.7398 0.5 12.35C0.5 9.8361 1.66836 8.01185 3.34365 6.73057C5.03362 5.43806 7.23613 4.70519 9.23481 4.43023L9.66667 4.37083V3.9349V0.5H12.3333V3.9349V4.37083L12.7652 4.43023C14.7639 4.70519 16.9664 5.43806 18.6564 6.73057C20.2185 7.9253 21.3399 9.59214 21.4842 11.85H18.8033C18.6385 10.5032 17.8144 9.50016 16.7812 8.7889C15.6328 7.99835 14.1833 7.53282 12.9249 7.29845L12.3333 7.18829V7.79V17.2349V17.6708L12.7652 17.7302C14.7639 18.0052 16.9664 18.7381 18.6564 20.0306C20.3316 21.3118 21.5 23.1361 21.5 25.65C21.5 28.1639 20.3316 29.9887 18.6563 31.2704C16.9663 32.5634 14.7638 33.2967 12.7652 33.5717L12.3333 33.6311V34.067ZM9.66667 7.79V7.18829L9.07512 7.29845C7.81666 7.53282 6.36717 7.99835 5.21878 8.7889C4.06152 9.58555 3.16667 10.7482 3.16667 12.35C3.16667 13.8934 4.04322 15.0474 5.18606 15.8528C6.32331 16.6544 7.77217 17.1505 9.07274 17.3992L9.66667 17.5128V16.9081V7.79ZM12.3333 30.21V30.8117L12.9249 30.7015C14.1833 30.4672 15.6328 30.0016 16.7812 29.2111C17.9385 28.4144 18.8333 27.2518 18.8333 25.65C18.8333 24.0482 17.9385 22.8855 16.7812 22.0889C15.6328 21.2984 14.1833 20.8328 12.9249 20.5985L12.3333 20.4883V21.09V30.21Z'
				fill={color}
				stroke={color}
			/>
		</SvgIcon>
	);
};

export default Paid;
