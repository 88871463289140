import React from 'react';

type Props = { color?: string };

const WishListSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='18'
			height='18'
			viewBox='0 0 18 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M9 3C9 3 9 3 9.76 2C10.64 0.84 11.94 0 13.5 0C15.99 0 18 2.01 18 4.5C18 5.43 17.72 6.29 17.24 7C16.43 8.21 9 16 9 16C9 16 1.57 8.21 0.76 7C0.28 6.29 0 5.43 0 4.5C0 2.01 2.01 0 4.5 0C6.06 0 7.37 0.84 8.24 2C9 3 9 3 9 3Z'
				fill={color}
			/>
		</svg>
	);
};

export default WishListSvg;
