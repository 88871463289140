import React from 'react';

type Props = { color?: string };

const HamburgerMenu = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='26'
			height='16'
			viewBox='0 0 26 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0 1.2C0 0.88174 0.128404 0.576515 0.356964 0.351472C0.585524 0.126428 0.895517 0 1.21875 0H24.7812C25.1045 0 25.4145 0.126428 25.643 0.351472C25.8716 0.576515 26 0.88174 26 1.2C26 1.51826 25.8716 1.82348 25.643 2.04853C25.4145 2.27357 25.1045 2.4 24.7812 2.4H1.21875C0.895517 2.4 0.585524 2.27357 0.356964 2.04853C0.128404 1.82348 0 1.51826 0 1.2ZM0 8C0 7.68174 0.128404 7.37652 0.356964 7.15147C0.585524 6.92643 0.895517 6.8 1.21875 6.8H24.7812C25.1045 6.8 25.4145 6.92643 25.643 7.15147C25.8716 7.37652 26 7.68174 26 8C26 8.31826 25.8716 8.62348 25.643 8.84853C25.4145 9.07357 25.1045 9.2 24.7812 9.2H1.21875C0.895517 9.2 0.585524 9.07357 0.356964 8.84853C0.128404 8.62348 0 8.31826 0 8ZM1.21875 13.6C0.895517 13.6 0.585524 13.7264 0.356964 13.9515C0.128404 14.1765 0 14.4817 0 14.8C0 15.1183 0.128404 15.4235 0.356964 15.6485C0.585524 15.8736 0.895517 16 1.21875 16H24.7812C25.1045 16 25.4145 15.8736 25.643 15.6485C25.8716 15.4235 26 15.1183 26 14.8C26 14.4817 25.8716 14.1765 25.643 13.9515C25.4145 13.7264 25.1045 13.6 24.7812 13.6H1.21875Z'
				fill={color}
			/>
		</svg>
	);
};

export default HamburgerMenu;
