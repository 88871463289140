import React from 'react';

type Props = { color?: string };

const FacebookSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='clamp(6px,3vw,13px)'
			height='clamp(12px,3vw,19px)'
			viewBox='0 0 9 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M7.03696 2.76266H8.53676C8.59684 2.76256 8.65441 2.74167 8.69686 2.70457C8.7393 2.66747 8.76313 2.61719 8.76313 2.56477V0.592538C8.76313 0.540053 8.73924 0.489722 8.6967 0.45261C8.65417 0.415498 8.59648 0.394643 8.53632 0.394643H6.17596C6.17596 0.394643 4.55739 0.359662 3.51976 1.52637C3.51484 1.53202 3.50949 1.53738 3.50376 1.54243C3.42641 1.6124 2.80448 2.22272 2.7944 3.81771V5.39588C2.79435 5.44838 2.7704 5.49871 2.72783 5.53582C2.68526 5.57293 2.62755 5.59378 2.56737 5.59378H0.463626C0.403472 5.59378 0.345783 5.61462 0.303247 5.65173C0.260712 5.68885 0.236816 5.73918 0.236816 5.79166V7.91036C0.236816 7.96284 0.260712 8.01318 0.303247 8.05029C0.345783 8.08741 0.403472 8.10825 0.463626 8.10825H2.56737C2.59718 8.10823 2.6267 8.11333 2.65424 8.12326C2.68178 8.1332 2.70681 8.14777 2.7279 8.16614C2.74898 8.18452 2.76571 8.20635 2.77712 8.23038C2.78853 8.2544 2.7944 8.28014 2.7944 8.30615V14.4064C2.79429 14.4325 2.80008 14.4583 2.81146 14.4825C2.82283 14.5066 2.83956 14.5285 2.86068 14.547C2.8818 14.5654 2.90689 14.5801 2.93452 14.59C2.96214 14.6 2.99176 14.6051 3.02165 14.6051H5.51788C5.57804 14.6051 5.63573 14.5842 5.67826 14.5471C5.7208 14.51 5.74469 14.4596 5.74469 14.4072V8.30691C5.74469 8.25443 5.76859 8.20409 5.81112 8.16697C5.85366 8.12986 5.91135 8.10902 5.9715 8.10902H8.08182C8.13721 8.10901 8.19068 8.09133 8.23217 8.05932C8.27366 8.02731 8.30031 7.98318 8.3071 7.93522L8.60513 5.81652C8.60901 5.78871 8.60607 5.76049 8.59652 5.73373C8.58697 5.70698 8.57103 5.68229 8.54974 5.6613C8.52844 5.64032 8.50229 5.62353 8.47301 5.61202C8.44372 5.60051 8.41197 5.59455 8.37986 5.59454H5.9715C5.91137 5.59449 5.85371 5.57362 5.81119 5.53652C5.76866 5.49942 5.74475 5.44912 5.74469 5.39665V3.81848C5.75039 3.62097 5.85163 2.74488 7.03696 2.76266Z'
				fill={color}
			/>
		</svg>
	);
};

export default FacebookSvg;
