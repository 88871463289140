import React from 'react';

type Props = { color?: string };

const UserSvg = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='18'
			height='20'
			viewBox='0 0 18 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M9 10.2248C13.3004 10.2248 17.7131 13.1366 17.9989 18.9863C18.0099 19.2118 17.9345 19.4326 17.7893 19.6003C17.6441 19.7679 17.4409 19.8687 17.2244 19.8804C14.9553 20.0008 4.44652 20.0747 0.77669 19.8804C0.559986 19.8689 0.356529 19.7683 0.211071 19.6006C0.0656123 19.433 -0.00993376 19.212 0.00104996 18.9863C0.286869 13.1377 4.69962 10.2248 9 10.2248ZM9 0C7.91502 0 6.87447 0.448855 6.10728 1.24782C5.34008 2.04679 4.90907 3.13042 4.90907 4.26033C4.90907 5.39024 5.34008 6.47387 6.10728 7.27284C6.87447 8.07181 7.91502 8.52066 9 8.52066C10.085 8.52066 11.1255 8.07181 11.8927 7.27284C12.6599 6.47387 13.0909 5.39024 13.0909 4.26033C13.0909 3.13042 12.6599 2.04679 11.8927 1.24782C11.1255 0.448855 10.085 0 9 0Z'
				fill={color}
			/>
		</svg>
	);
};

export default UserSvg;
