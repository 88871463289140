import React from 'react';

type Props = { color?: string };

const Processing = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='21'
			height='21'
			viewBox='0 0 41 41'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M13.61 13.2708H4.42253C3.88104 13.2708 3.36174 13.0557 2.97885 12.6728C2.59596 12.29 2.38086 11.7707 2.38086 11.2292V2.04167C2.38086 1.50018 2.59596 0.980877 2.97885 0.59799C3.36174 0.215103 3.88104 0 4.42253 0C4.96401 0 5.48332 0.215103 5.8662 0.59799C6.24909 0.980877 6.46419 1.50018 6.46419 2.04167V9.1875H13.61C14.1515 9.1875 14.6708 9.4026 15.0537 9.78549C15.4366 10.1684 15.6517 10.6877 15.6517 11.2292C15.6517 11.7707 15.4366 12.29 15.0537 12.6728C14.6708 13.0557 14.1515 13.2708 13.61 13.2708Z'
				fill={color}
			/>
			<path
				d='M38.8747 22.4585C38.3332 22.4585 37.8139 22.2434 37.431 21.8605C37.0481 21.4776 36.833 20.9583 36.833 20.4168C36.8345 16.8225 35.6497 13.3282 33.4624 10.4762C31.2751 7.62407 28.2075 5.57368 24.7358 4.64315C21.2641 3.71262 17.5823 3.95399 14.2617 5.32981C10.9412 6.70562 8.16759 9.13895 6.37133 12.2522C6.10004 12.7208 5.6537 13.0625 5.13048 13.2021C4.60727 13.3417 4.05004 13.2677 3.58139 12.9964C3.11274 12.7251 2.77104 12.2787 2.63148 11.7555C2.49192 11.2323 2.56592 10.6751 2.8372 10.2064C5.08355 6.31551 8.55119 3.27467 12.7022 1.55567C16.8531 -0.163333 21.4554 -0.464393 25.7949 0.69919C30.1344 1.86277 33.9686 4.42595 36.7027 7.99108C39.4368 11.5562 40.918 15.924 40.9163 20.4168C40.9163 20.9583 40.7012 21.4776 40.3183 21.8605C39.9355 22.2434 39.4161 22.4585 38.8747 22.4585ZM36.5778 40.8335C36.0363 40.8335 35.517 40.6184 35.1341 40.2355C34.7512 39.8526 34.5361 39.3333 34.5361 38.7918V31.646H27.3903C26.8488 31.646 26.3295 31.4309 25.9466 31.048C25.5637 30.6651 25.3486 30.1458 25.3486 29.6043C25.3486 29.0628 25.5637 28.5435 25.9466 28.1606C26.3295 27.7777 26.8488 27.5626 27.3903 27.5626H36.5778C37.1193 27.5626 37.6386 27.7777 38.0215 28.1606C38.4043 28.5435 38.6195 29.0628 38.6195 29.6043V38.7918C38.6195 39.3333 38.4043 39.8526 38.0215 40.2355C37.6386 40.6184 37.1193 40.8335 36.5778 40.8335Z'
				fill={color}
			/>
			<path
				d='M20.5006 40.8333C15.0875 40.8279 9.89755 38.6752 6.06986 34.8475C2.24216 31.0198 0.0893894 25.8298 0.0839844 20.4167C0.0839844 19.8752 0.299088 19.3559 0.681975 18.973C1.06486 18.5901 1.58417 18.375 2.12565 18.375C2.66713 18.375 3.18644 18.5901 3.56933 18.973C3.95221 19.3559 4.16732 19.8752 4.16732 20.4167C4.16576 24.0109 5.35058 27.5052 7.53792 30.3573C9.72525 33.2094 12.7928 35.2598 16.2645 36.1903C19.7363 37.1209 23.4181 36.8795 26.7386 35.5037C30.0591 34.1279 32.8327 31.6945 34.629 28.5813C34.7633 28.3492 34.942 28.1459 35.1549 27.9829C35.3678 27.8199 35.6108 27.7005 35.8698 27.6314C36.1289 27.5623 36.399 27.5449 36.6648 27.5802C36.9306 27.6155 37.1869 27.7028 37.4189 27.8371C37.651 27.9714 37.8543 28.1501 38.0173 28.3631C38.1803 28.576 38.2997 28.8189 38.3688 29.0779C38.4379 29.337 38.4553 29.6072 38.42 29.873C38.3847 30.1387 38.2974 30.395 38.1631 30.627C36.3678 33.7235 33.7922 36.2952 30.6932 38.086C27.5942 39.8768 24.0799 40.8241 20.5006 40.8333Z'
				fill={color}
			/>
		</svg>
	);
};

export default Processing;
