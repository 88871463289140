import React from 'react';

type Props = { color?: string };

const StarSolid = ({ color = 'white' }: Props) => {
	return (
		<svg
			width='13'
			height='12'
			viewBox='0 0 13 12'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={{
				marginLeft: '1px',
			}}
		>
			<path
				d='M4.52204 2.05399C5.31662 0.752045 5.7136 0.101074 6.30749 0.101074C6.90139 0.101074 7.29836 0.752045 8.09294 2.05399L8.29864 2.39093C8.52441 2.76111 8.6373 2.9462 8.81289 3.06826C8.98849 3.19032 9.20799 3.23559 9.64698 3.32613L10.0458 3.40865C11.5886 3.72783 12.3593 3.88713 12.5431 4.42636C12.7262 4.96502 12.2007 5.52717 11.149 6.65089L10.8768 6.94142C10.5783 7.2606 10.4284 7.42048 10.3613 7.61761C10.2942 7.8153 10.3168 8.02847 10.3619 8.45424L10.4033 8.84219C10.562 10.3418 10.6416 11.0914 10.1612 11.4243C9.68085 11.7572 8.95839 11.4535 7.51473 10.8461L7.14033 10.6891C6.73018 10.516 6.52511 10.4301 6.30749 10.4301C6.08988 10.4301 5.8848 10.516 5.47466 10.6891L5.10089 10.8461C3.65659 11.4535 2.93414 11.7572 2.45438 11.4249C1.97337 11.0914 2.05301 10.3418 2.21168 8.84219L2.25307 8.45481C2.29822 8.02847 2.3208 7.8153 2.25307 7.61818C2.18659 7.42048 2.03671 7.2606 1.73819 6.94199L1.46601 6.65089C0.414309 5.52774 -0.11123 4.96559 0.0718933 4.42636C0.255017 3.88713 1.02702 3.72725 2.56977 3.40865L2.96863 3.32613C3.407 3.23559 3.62586 3.19032 3.80209 3.06826C3.97831 2.9462 4.09057 2.76111 4.31634 2.39093L4.52204 2.05399Z'
				fill={color}
			/>
		</svg>
	);
};

export default StarSolid;
